import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import urlJoin from 'url-join';

import { PlusIcon, ArrowRightIcon } from 'assets/icons';

import './SelectDemoSection.scss';

const SelectDemoSection = () => {
  const demoMetadataList = useSelector((state) => state.demoMetadataList);
  return (
    <div className={'section select-demo'} id={'demo'}>
      <div className={'section-content'}>
        <div className={'section-title'}>
          <h2>Explore the model performance on the following data sets</h2>
        </div>

        <div className={'select-demo-wrapper'}>
          <div className="insertion" />
          <div className={'select-demo-panel'}>
            {demoMetadataList.map((demoMetadata, index) => (
              <DemoTile key={index} {...demoMetadata} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const DemoTile = (props) => {
  const { datasetName, datasetSemId, datasetRootURL } = props;
  const imageURL = urlJoin(datasetRootURL, '1.jpg');
  const history = useHistory();
  // TODO: On hover show slices from dataset.

  return (
    <div className={'tile'} onClick={() => history.push(`/${datasetSemId}/1`)}>
      <div className={'substrate'} />

      <div className={'image-wrapper'}>
        <img src={imageURL} alt={datasetName} />
      </div>

      <div className={'demo-desc'}>
        <p className={'demo-name'}>{datasetName}</p>
        <div className={'try-label-wrapper'}>
          <p className={'try-label'}>TRY DEMO</p>
          <div className={'icon-wrapper'}>
            <div className={'plus-icon'}>
              <PlusIcon />
            </div>
            <div className={'arrow-icon'}>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDemoSection;
