import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  LayersIcon,
  NextIcon,
  PrevIcon,
  RedoIcon,
  ResetIcon,
  RunIcon,
  InvertAnnotationsIcon,
  TrainIcon,
  UndoIcon,
} from 'assets/icons';

import {
  canvasIsEmpty,
  getImgSize,
  getImgId,
  readImageDataFromCanvas,
  sampleModelId,
} from 'utils/misc';
import { backendAPI } from 'utils/api';

import DemoPageContext from 'components/DemoPage/context';
import { buttonLabels, tools } from 'components/DemoPage/demoConstants';
import {
  resetMaskCanvasCache,
  resetScribbleCanvasCache,
  SCRIBBLE_CANVAS_CACHE,
} from 'components/DemoPage/ImagePlayer/canvas';

import Checkboxes from './Checkboxes';
import { ButtonWithShortcut, ButtonWithTooltip } from './buttons';

import './ToolsPanel.scss';

export const ToolsPanel = () => {
  return (
    <div className={'tools-panel'}>
      <div className={'row row-checkboxes'}>
        <div className={'row-content'}>
          <ItemPanel customClassNames={'layer-checkboxes'}>
            <LayerCheckboxes />
          </ItemPanel>
        </div>
      </div>

      <div className={'row-wrapper'}>
        <div className={'row row-tools'}>
          <div className={'row-content'}>
            <ItemPanel customClassNames={'prev-next'}>
              <UndoButton />
              <RedoButton />
            </ItemPanel>

            <ItemPanel>
              <AnnotateFGButton />
              <AnnotateBGButton />
              <InvertAnnotationsButton />
            </ItemPanel>

            <ItemPanel>
              <ResetModelButton />
              <ItemMessage
                message={
                  'Annotate foreground and background to train a new model'
                }
              />
              <TrainModelButton />
            </ItemPanel>

            <ItemPanel>
              <RunModelButton />
            </ItemPanel>

            <ItemPanel customClassNames={'prev-next'}>
              <PrevImageButton />
              <CurrentImageIndicator />
              <NextImageButton />
            </ItemPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemPanel = (props) => {
  const { customClassNames, children } = props;
  return (
    <div className={classNames('item-panel', customClassNames)}>{children}</div>
  );
};

const UndoButton = () => {
  const { scribblePrevHistory } = React.useContext(DemoPageContext);
  return (
    <ButtonWithTooltip
      message={'undo scribbles'}
      hotKeyId={tools.UNDO}
      Icon={UndoIcon}
      onClick={() => window.dispatchEvent(new Event('scribbles-undo'))}
      isInactive={scribblePrevHistory.length === 0}
    />
  );
};

const RedoButton = () => {
  const { scribbleNextHistory } = React.useContext(DemoPageContext);
  return (
    <ButtonWithTooltip
      message={'redo scribbles'}
      hotKeyId={tools.REDO}
      Icon={RedoIcon}
      onClick={() => window.dispatchEvent(new Event('scribbles-redo'))}
      isInactive={scribbleNextHistory.length === 0}
    />
  );
};

const AnnotateFGButton = () => {
  const { currentToolId, setCurrentToolId } = useContext(DemoPageContext);
  return (
    <ButtonWithShortcut
      label={buttonLabels[tools.ANNOTATE_FG]}
      Icon={LayersIcon}
      hotKeyId={tools.ANNOTATE_FG}
      isActive={currentToolId === tools.ANNOTATE_FG}
      customClassNames={'item fg'}
      onClick={() => setCurrentToolId(tools.ANNOTATE_FG)}
    />
  );
};

const AnnotateBGButton = () => {
  const { currentToolId, setCurrentToolId } = useContext(DemoPageContext);
  return (
    <ButtonWithShortcut
      label={buttonLabels[tools.ANNOTATE_BG]}
      Icon={LayersIcon}
      hotKeyId={tools.ANNOTATE_BG}
      isActive={currentToolId === tools.ANNOTATE_BG}
      customClassNames={'item bg'}
      onClick={() => setCurrentToolId(tools.ANNOTATE_BG)}
    />
  );
};

const InvertAnnotationsButton = () => {
  const { currentToolId, setCurrentToolId } = useContext(DemoPageContext);
  return (
    <ButtonWithShortcut
      label={buttonLabels[tools.INVERT_ANNOTATIONS]}
      Icon={InvertAnnotationsIcon}
      hotKeyId={tools.INVERT_ANNOTATIONS}
      isActive={currentToolId === tools.INVERT_ANNOTATIONS}
      customClassNames={'item switch'}
      onClick={() => setCurrentToolId(tools.INVERT_ANNOTATIONS)}
    />
  );
};

const ResetModelButton = () => {
  const { setModelId, scribbleCanvasRef, maskCanvasRef } = useContext(
    DemoPageContext,
  );
  return (
    <ButtonWithShortcut
      label={buttonLabels[tools.RESET_MODEL]}
      Icon={ResetIcon}
      hotKeyId={tools.RESET_MODEL}
      customClassNames={'item reset'}
      onClick={() => {
        resetScribbleCanvasCache();
        resetMaskCanvasCache();

        const scribbleCanvas = scribbleCanvasRef.current;
        scribbleCanvas
          .getContext('2d')
          .clearRect(0, 0, scribbleCanvas.width, scribbleCanvas.height);

        const maskCanvas = maskCanvasRef.current;
        maskCanvas
          .getContext('2d')
          .clearRect(0, 0, maskCanvas.width, maskCanvas.height);

        setModelId(sampleModelId());
      }}
    />
  );
};

const TrainModelButton = () => {
  const {
    datasetSemId,
    sliceIndex,
    modelId,
    setSegResults,
    errorMessages,
    setErrorMessages,
    setIsRunning,
    imgRef,
    scribbleCanvasRef,
  } = useContext(DemoPageContext);

  const displayErrorMessage = ({ title, description }) =>
    setErrorMessages([...errorMessages, { title, description }]);

  return (
    <ButtonWithShortcut
      label={buttonLabels[tools.TRAIN_MODEL]}
      Icon={TrainIcon}
      hotKeyId={tools.TRAIN_MODEL}
      customClassNames={'item train'}
      onClick={() => {
        const canvas = scribbleCanvasRef.current;

        if (canvasIsEmpty(canvas)) {
          displayErrorMessage({
            title: 'Missing object annotations',
            description:
              'Before training a model, use the scribble tool to annotate background and foreground pixels.',
          });
        } else {
          setIsRunning(true);
          backendAPI.trainModel({
            datasetSemId,
            sliceIndex,
            modelId,
            maskImageData: readImageDataFromCanvas(
              canvas,
              getImgSize(imgRef.current),
            ),
            callbackOnFulfilled: ({ mask, maskWidth, maskHeight }) => {
              setIsRunning(false);
              setSegResults({ mask, maskWidth, maskHeight });
            },
            callbackOnRejected: (errorMessage) => {
              setIsRunning(false);
              displayErrorMessage(errorMessage);
            },
          });
        }
      }}
    />
  );
};

const RunModelButton = () => {
  const {
    datasetSemId,
    sliceIndex,
    modelId,
    errorMessages,
    setIsRunning,
    setSegResults,
    setErrorMessages,
  } = useContext(DemoPageContext);
  return (
    <ButtonWithShortcut
      label={buttonLabels[tools.RUN_MODEL]}
      Icon={RunIcon}
      hotKeyId={tools.RUN_MODEL}
      customClassNames={'item run'}
      onClick={() => {
        setIsRunning(true);
        backendAPI.runModel({
          datasetSemId,
          sliceIndex,
          modelId,
          callbackOnFulfilled: ({ mask, maskWidth, maskHeight }) => {
            setIsRunning(false);
            setSegResults({ mask, maskWidth, maskHeight });
          },
          callbackOnRejected: (errorMessage) => {
            setIsRunning(false);
            setErrorMessages([...errorMessages, errorMessage]);
          },
        });
      }}
    />
  );
};

const ItemMessage = (props) => {
  const { message } = props;
  return <div className={'item message'}>{message}</div>;
};

const PrevImageButton = () => {
  const {
    datasetSemId,
    sliceIndex,
    setSliceIndex,
    setSegResults,
    setScribblePrevHistory,
    setScribbleNextHistory,
  } = useContext(DemoPageContext);
  return (
    <ButtonWithTooltip
      message={'previous'}
      hotKeyId={tools.PREV_IMAGE}
      Icon={PrevIcon}
      onClick={() => {
        const prevHistory = [];
        const imageData =
          SCRIBBLE_CANVAS_CACHE[
            getImgId({ datasetSemId, sliceIndex: sliceIndex - 1 })
          ];
        if (imageData) {
          prevHistory.push(imageData);
        }

        setSegResults(null);
        setScribblePrevHistory(prevHistory);
        setScribbleNextHistory([]);
        setSliceIndex((sliceIndex) =>
          sliceIndex > 1 ? sliceIndex - 1 : sliceIndex,
        );
      }}
      isInactive={sliceIndex - 1 < 1}
    />
  );
};

const NextImageButton = () => {
  const {
    datasetSemId,
    numSlices,
    sliceIndex,
    setSliceIndex,
    setSegResults,
    setScribblePrevHistory,
    setScribbleNextHistory,
  } = useContext(DemoPageContext);
  return (
    <ButtonWithTooltip
      message={'next'}
      hotKeyId={tools.NEXT_IMAGE}
      Icon={NextIcon}
      onClick={() => {
        const prevHistory = [];
        const imgId = getImgId({ datasetSemId, sliceIndex: sliceIndex + 1 });
        const imageData = SCRIBBLE_CANVAS_CACHE[imgId];
        if (imageData) {
          prevHistory.push(imageData);
        }

        setSegResults(null);
        setScribblePrevHistory(prevHistory);
        setScribbleNextHistory([]);
        setSliceIndex((sliceIndex) =>
          sliceIndex < numSlices ? sliceIndex + 1 : sliceIndex,
        );
      }}
      isInactive={sliceIndex >= numSlices}
    />
  );
};

const CurrentImageIndicator = () => {
  const { sliceIndex, numSlices } = useContext(DemoPageContext);
  return (
    <div className={'slice-navigator'}>
      <div className={'content'}>
        {sliceIndex} / {numSlices}
      </div>
    </div>
  );
};

const LayerCheckboxes = () => {
  const {
    imageIsShown,
    setImageIsShown,
    scribbleIsShown,
    setScribbleIsShown,
    maskIsShown,
    setMaskIsShown,
  } = useContext(DemoPageContext);
  return (
    <Checkboxes
      items={[
        {
          label: 'Image',
          isChecked: imageIsShown,
          onChange: setImageIsShown,
          hotKeyId: tools.TOGGLE_IMAGE,
        },
        {
          label: 'Scribbles',
          isChecked: scribbleIsShown,
          onChange: setScribbleIsShown,
          hotKeyId: tools.TOGGLE_SCRIBBLES,
        },
        {
          label: 'Mask',
          isChecked: maskIsShown,
          onChange: setMaskIsShown,
          hotKeyId: tools.TOGGLE_MASK,
        },
      ]}
    />
  );
};
