import React from 'react';

import Navigation from 'components/Navigation';

import './Header.scss';
import { PlayIcon } from '../../../assets/icons';
import config from '../../../config';

const Header = () => {
  return (
    <header>
      {/*<div className={'image-wrapper'}>*/}
      {/*  <img src={'/static/image/header.jpg'} alt={''} />*/}
      {/*</div>*/}

      <div className={'video-wrapper'}>
        <video autoPlay muted loop id="demoVideo">
          <source src="/static/video/demo.mp4" type="video/mp4" />
        </video>
      </div>

      <Navigation isFadeInOnOpen={true} />

      <div className={'content-wrapper'}>
        <div className={'section intro'}>
          <div className={'section-content'}>
            <div className={'header-content'}>
              <div className={'text'}>
                <div className={'main-text'}>
                  <h1>
                    Semi&#8209;automated segmentation of bio&#8209;medical
                    images
                  </h1>
                </div>

                <div className={'try-demo-wrapper'}>
                  <a href="#demo">
                    <div className={'try-demo'}>Try demo →</div>
                  </a>
                </div>
              </div>

              <div className={'play-button-wrapper'}>
                <PlayButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const PlayButton = () => {
  return (
    <a
      className={'play-button-link'}
      href={config.videoUrl}
      target={'_blank'}
      rel={'noreferrer'}
    >
      <div className={'play-button'}>
        <div className={'content'}>
          <PlayIcon />
          <div className={'title'}>Demo Walkthrough</div>
        </div>
      </div>
    </a>
  );
};

export default Header;
