import React from 'react';
import _ from 'lodash';

import './Spinner.scss';

const Spinner = () => {
  return (
    <div className={'spinner'}>
      <div className="sk-circle">
        {_.range(1, 13).map((i) => (
          <div className={`sk-circle${i} sk-child`} />
        ))}
      </div>
    </div>
  );
};

export default Spinner;
