import React from 'react';

import { ControlIcon, EffortIcon, QualityIcon, TimeIcon } from 'assets/icons';

import './BenefitsSection.scss';

const BENEFITS = [
  {
    Icon: QualityIcon,
    desc:
      'From only a few scribbles, the model can learn to recognize similar objects.',
  },
  {
    Icon: EffortIcon,
    desc: 'The model learns in real time to help experts segment objects.',
  },
  {
    Icon: TimeIcon,
    desc: 'Experts require significantly less time to annotate images.',
  },
  {
    Icon: ControlIcon,
    desc: 'Annotations can be corrected though a user-friendly web-interface',
  },
];

const BenefitsSection = () => {
  return (
    <>
      {/*<p style={{color: 'white'}}>{`${window.innerWidth}x${window.innerHeight}`}</p>*/}
      <div className={'section value'}>
        <div className={'section-content'}>
          <div className={'benefits'}>
            {BENEFITS.map(({ Icon, desc }, index) => (
              <div key={index} className={'benefit'}>
                <div className={'icon-wrapper'}>
                  <Icon />
                </div>
                <div className={'description'}>{desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BenefitsSection;
