let botUrl = 'http://127.0.0.1:9999';
let apiUrl = 'http://127.0.0.1:8080';
if (process.env.NODE_ENV === 'production') {
  botUrl = 'https://bot.samuylov.ai';
  apiUrl = 'https://iseg-api.samuylov.ai';
}

const subject = encodeURIComponent('Interactive annotations');
const contactEmail = 'contact@samuylov.ai';

const config = {
  app: {
    version: '0.0.1',
    localStorageKey: 'iseg',
  },
  backend: {
    bot: botUrl,
    api: apiUrl,
  },
  contactEmailStr: `mailto:${contactEmail}?subject=${subject}`,
  homeUrl: 'https://www.samuylov.ai',
  videoUrl: 'https://www.youtube.com/watch?v=ypRxx5izKYo',
};

export default config;
