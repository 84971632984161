import React, { useState, useEffect } from 'react';

import {
  computeDisplayedImgSize,
  draw,
  writeImageDataToCanvas,
  readImageDataFromCanvas,
  clearCanvas,
} from 'utils/misc';

import DemoPageContext from 'components/DemoPage/context';

import './ScribbleCanvas.scss';

export const SCRIBBLE_CANVAS_CACHE = {};

export const resetScribbleCanvasCache = () => {
  Object.keys(SCRIBBLE_CANVAS_CACHE).forEach((key) => {
    delete SCRIBBLE_CANVAS_CACHE[key];
  });
};

const ScribbleCanvas = (props) => {
  const { imgId, imgRef, canvasRef, drawIsAllowed, color } = props;
  const [isDragging, setIsDragging] = useState(false);

  const {
    scribblePrevHistory,
    setScribblePrevHistory,
    scribbleNextHistory,
    setScribbleNextHistory,
  } = React.useContext(DemoPageContext);

  const cacheCanvas = () => {
    const canvas = canvasRef.current;
    SCRIBBLE_CANVAS_CACHE[imgId] = canvas
      .getContext('2d')
      .getImageData(0, 0, canvas.width, canvas.height);
    // TODO: Can we reuse read from canvas method?
  };

  useEffect(() => {
    // Resize canvas and redraw image.

    const img = imgRef.current;
    const canvas = canvasRef.current;

    const updateCanvas = () => {
      const { width, height } = computeDisplayedImgSize(img);
      canvas.width = width;
      canvas.height = height;

      const imageDataCache = SCRIBBLE_CANVAS_CACHE[imgId] || null;
      if (imageDataCache !== null) {
        writeImageDataToCanvas(canvas, imageDataCache);
      }
    };

    // On the first call:
    updateCanvas();
    window.addEventListener('resize', updateCanvas);

    return () => {
      window.removeEventListener('resize', updateCanvas);
    };
  }, [imgId, imgRef, canvasRef]);

  useEffect(() => {
    // Subscribe to undo/redo events.

    const undo = () => {
      const numPrev = scribblePrevHistory.length;
      if (numPrev === 0) {
        // Nothing to undo.
      } else if (numPrev === 1) {
        const [currentImageData] = scribblePrevHistory.slice(-1);
        clearCanvas(canvasRef.current);
        cacheCanvas();
        setScribblePrevHistory([]);
        setScribbleNextHistory([currentImageData, ...scribbleNextHistory]);
      } else {
        const [prevImageData, currentImageData] = scribblePrevHistory.slice(-2);
        clearCanvas(canvasRef.current);
        writeImageDataToCanvas(canvasRef.current, prevImageData, cacheCanvas);
        setScribblePrevHistory([...scribblePrevHistory.slice(0, -1)]);
        setScribbleNextHistory([currentImageData, ...scribbleNextHistory]);
      }
    };

    const redo = (event) => {
      const numNext = scribbleNextHistory.length;
      if (numNext === 0) {
        // Nothing to redo.
      } else {
        const [nextImageData] = scribbleNextHistory.slice(0, 1);
        clearCanvas(canvasRef.current);
        writeImageDataToCanvas(canvasRef.current, nextImageData, cacheCanvas);
        setScribblePrevHistory([...scribblePrevHistory, nextImageData]);
        setScribbleNextHistory([...scribbleNextHistory.slice(1)]);
      }
    };

    window.addEventListener('scribbles-undo', undo);
    window.addEventListener('scribbles-redo', redo);

    return () => {
      window.removeEventListener('scribbles-undo', undo);
      window.removeEventListener('scribbles-redo', redo);
    };
  });

  const drawFromEvent = (event) => {
    const canvas = canvasRef.current;

    const { x, y } = canvas.getBoundingClientRect();
    const clickCoords = {
      x: event.clientX - x,
      y: event.clientY - y,
    };

    draw({ canvas, clickCoords, color });

    SCRIBBLE_CANVAS_CACHE[imgId] = canvas
      .getContext('2d')
      .getImageData(0, 0, canvas.width, canvas.height);
  };

  return (
    <canvas
      className={'scribble-canvas'}
      ref={canvasRef}
      onMouseDown={(event) => {
        if (drawIsAllowed) {
          setIsDragging(true);
          drawFromEvent(event);
        }
      }}
      onMouseUp={(event) => {
        setIsDragging(false);

        const canvas = canvasRef.current;
        const imageData = readImageDataFromCanvas(canvas, {
          width: canvas.width,
          height: canvas.height,
        });

        setScribblePrevHistory([...scribblePrevHistory, imageData]);
        setScribbleNextHistory([]);
      }}
      onMouseMove={(event) => {
        if (!isDragging) {
          return;
        }
        drawFromEvent(event);
      }}
    />
  );
};

export default ScribbleCanvas;
