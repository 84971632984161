import React, { useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import classNames from 'classnames';

import { KEY_MAP, mapKeyMapToLabel } from 'components/DemoPage/demoHotKeys';

import './buttons.scss';

const Button = (props) => {
  const {
    label,
    Icon,
    onClick,
    isActive,
    isInactive,
    customClassNames,
    hotKeyId,
    children,
  } = props;
  const [isDown, setIsDown] = useState(false);
  return (
    <GlobalHotKeys handlers={{ [hotKeyId]: onClick }} allowChanges={true}>
      <div
        className={classNames('button', customClassNames, {
          active: isActive || isDown,
          inactive: isInactive,
        })}
        onMouseDown={(event) => {
          event.preventDefault();
          !(isActive || isInactive) && setIsDown(true);
        }}
        onMouseUp={() => isDown && setIsDown(false)}
        onMouseLeave={() => isDown && setIsDown(false)}
        onClick={() => onClick && !(isActive || isInactive) && onClick()}
      >
        <div className={'button-content'}>
          {Icon && (
            <div className={'icon-wrapper'}>
              <Icon />
            </div>
          )}
          {label && <div className={'label'}>{label}</div>}
        </div>
        {children}
      </div>
    </GlobalHotKeys>
  );
};

export const ButtonWithShortcut = (props) => {
  const { hotKeyId, customClassNames, ...otherProps } = props;
  const shortcutKey = mapKeyMapToLabel(KEY_MAP[hotKeyId]);
  return (
    <Button
      customClassNames={classNames('with-shortcut', customClassNames)}
      hotKeyId={hotKeyId}
      {...otherProps}
    >
      <div className={'shortcut-key'}>{shortcutKey}</div>
    </Button>
  );
};

export const ButtonWithTooltip = (props) => {
  const { message, hotKeyId, customClassNames, ...otherProps } = props;
  const shortcutKey = mapKeyMapToLabel(KEY_MAP[hotKeyId]);
  return (
    <Button
      customClassNames={classNames('with-tooltip', customClassNames)}
      hotKeyId={hotKeyId}
      {...otherProps}
    >
      <div className={'tooltip-wrapper'}>
        <div className={'tooltip'}>
          <div className={'message'}>{message}</div>
          <div className={'shortcut-key'}>{shortcutKey}</div>
        </div>
        <div className={'tip'} />
      </div>
    </Button>
  );
};
