import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalHotKeys } from 'react-hotkeys';

import { ProcessingPopup } from 'components/Popup';
import Navigation from 'components/Navigation';
import NotFoundPage from 'components/NotFoundPage';
import MessagePanel from './MessagePanel';
import ImagePlayer from './ImagePlayer';
import ToolsPanel from './ToolsPanel';

import { sampleModelId } from 'utils/misc';
import { KEY_MAP } from './demoHotKeys';
import { tools } from './demoConstants';
import DemoPageContext from './context';

import './DemoPage.scss';

const getDemoSelIsShown = () => window.innerWidth >= 768;

const DemoPage = (props) => {
  const { datasetSemId, datasetRootURL, numSlices } = props;

  const history = useHistory();
  const params = useParams();

  const [sliceIndex, setSliceIndex] = useState(parseInt(params.slice_index));
  const [modelId, setModelId] = useState(sampleModelId());
  const [currentToolId, setCurrentToolId] = useState(tools.ANNOTATE_FG);
  const [errorMessages, setErrorMessages] = useState([]);
  const [segResults, setSegResults] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const [imageIsShown, setImageIsShown] = useState(true);
  const [maskIsShown, setMaskIsShown] = useState(true);
  const [scribbleIsShown, setScribbleIsShown] = useState(true);

  const imgRef = useRef(null);
  const scribbleCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);

  const [scribblePrevHistory, setScribblePrevHistory] = useState([]);
  const [scribbleNextHistory, setScribbleNextHistory] = useState([]);

  const [demoSelIsShown, setDemoSelIsShown] = useState(getDemoSelIsShown());

  const providerValue = {
    datasetSemId,
    datasetRootURL,
    numSlices,
    sliceIndex,
    setSliceIndex,
    modelId,
    setModelId,
    currentToolId,
    setCurrentToolId,
    errorMessages,
    setErrorMessages,
    segResults,
    setSegResults,
    isRunning,
    setIsRunning,
    imgRef,
    scribbleCanvasRef,
    maskCanvasRef,
    imageIsShown,
    setImageIsShown,
    scribbleIsShown,
    setScribbleIsShown,
    maskIsShown,
    setMaskIsShown,
    scribblePrevHistory,
    setScribblePrevHistory,
    scribbleNextHistory,
    setScribbleNextHistory,
  };

  useEffect(() => {
    // Run on current slice index update:
    if (parseInt(params.slice_index) !== sliceIndex) {
      setSegResults(null);
      history.push(`${sliceIndex}`);
    }
  }, [params, history, sliceIndex]);

  useEffect(() => {
    const checkDemoSelStatus = () => {
      const newDemoSelIsShown = getDemoSelIsShown();
      if (newDemoSelIsShown !== demoSelIsShown) {
        setDemoSelIsShown(newDemoSelIsShown);
      }
    };
    window.addEventListener('resize', checkDemoSelStatus);
    return () => window.removeEventListener('resize', checkDemoSelStatus);
  });

  if (sliceIndex < 1 || sliceIndex > numSlices) {
    return <NotFoundPage />;
  }

  return (
    <GlobalHotKeys keyMap={KEY_MAP}>
      <DemoPageContext.Provider value={providerValue}>
        {isRunning && <ProcessingPopup />}

        <Navigation
          className={'dark'}
          currentDatasetSemId={demoSelIsShown ? datasetSemId : null}
        />

        <ImagePlayer />
        <ToolsPanel />

        <MessagePanel
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
        />
      </DemoPageContext.Provider>
    </GlobalHotKeys>
  );
};

export default DemoPage;
