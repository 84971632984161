import React from 'react';

import { LinkedInInvIcon } from 'assets/icons';

import './TeamSection.scss';

const TeamSection = () => {
  return (
    <div className={'section team'}>
      <div className={'section-content'}>
        <div className={'bio-wrapper'}>
          <div className={'insert'} />
          <div className={'photo-wrapper'}>
            <img
              src={'/static/image/denis_samuylov.jpg'}
              alt={'Denis Samuylov'}
            />
          </div>
          <div className={'name'}>Denis Samuylov</div>
          <div className={'role-wrapper'}>
            <div className={'role'}>Founder</div>
          </div>
          <div className={'bio'}>
            Denis has gathered a unique set of expertise and experience across
            multicultural and interdisciplinary environment in academia,
            startups and corporates. He holds a PhD in Computer Vision from ETH
            Zürich and has 8 years of experience in analysing bio-medical data.
            The results of Denis' work are published at high-impact journals and
            presented at top tier conferences. He is passionate about
            identifying technologies to solve given tasks, designing and
            implementing proof-of-concepts and minimal viable products as well
            as scaling solutions to work in production.
          </div>

          <div className={'contacts'}>
            <a
              className={'contact'}
              href={'https://www.linkedin.com/in/denis-samuylov-b45ba234/'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <div>
                <LinkedInInvIcon />{' '}
                <div className={'website-name'}>LinkedIn ↗</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
