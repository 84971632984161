import React from 'react';

import Header from './Header';
import SelectDemoSection from './SelectDemoSection';
import WorkflowSection from './WorkflowSection';
import BenefitsSection from './BenefitsSection';
import ContactSection from './ContactSection';
import Footer from './Footer';
import TeamSection from './TeamSection';

import './LandingPage.scss';

const LandingPage = () => {
  return (
    <>
      <Header />
      <BenefitsSection />
      <WorkflowSection />
      <SelectDemoSection />
      <ContactSection />
      <TeamSection />
      <Footer />
    </>
  );
};

export default LandingPage;
