import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import LandingPage from 'components/LandingPage';
import DemoPage from 'components/DemoPage';
import NotFoundPage from 'components/NotFoundPage';

import { urls } from 'appConstants';
import { backendAPI } from 'utils/api';
import * as actions from 'storage/actions';

import './Main.scss';

const Main = () => {
  const [isUpdated, setIsUpdated] = useState(false);

  const demoMetadataList = useSelector((state) => state.demoMetadataList);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch data from the server:
    if (!isUpdated || demoMetadataList === null) {
      backendAPI.getDemoMetadataList({
        callbackOnFulfilled: (data) => {
          setIsUpdated(true);
          dispatch({ type: actions.UPDATE_DEMO_METADATA_LIST, data });
        },
      });
    }
  });

  let mainPageContent = null;
  if (demoMetadataList !== null) {
    mainPageContent = (
      <div className={'demo'}>
        <Switch>
          <Route exact path={urls.HOME}>
            <LandingPage />
          </Route>
          {demoMetadataList.map((metadata, index) => {
            return (
              <Route
                key={index}
                exact
                path={`/${metadata.datasetSemId}/:slice_index(\\d+)`}
              >
                <DemoPage {...metadata} />
              </Route>
            );
          })}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    );
  }

  return mainPageContent;
};

export default Main;
