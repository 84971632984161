import React from 'react';

export const tools = {
  ANNOTATE_BG: 'ANNOTATE_BG',
  ANNOTATE_FG: 'ANNOTATE_FG',
  INVERT_ANNOTATIONS: 'INVERT_ANNOTATIONS',
  RESET_MODEL: 'RESET_MODEL',
  TRAIN_MODEL: 'TRAIN_MODEL',
  RUN_MODEL: 'RUN_MODEL',
  UNDO: 'UNDO',
  REDO: 'REDO',
  NEXT_IMAGE: 'NEXT_IMAGE',
  PREV_IMAGE: 'PREV_IMAGE',
  TOGGLE_IMAGE: 'TOGGLE_IMAGE',
  TOGGLE_SCRIBBLES: 'TOGGLE_SCRIBBLES',
  TOGGLE_MASK: 'TOGGLE_MASK',
};

export const buttonLabels = {
  [tools.ANNOTATE_FG]: (
    <>
      Annotate
      <br />
      foreground
    </>
  ),
  [tools.ANNOTATE_BG]: (
    <>
      Annotate
      <br />
      background
    </>
  ),
  [tools.INVERT_ANNOTATIONS]: (
    <>
      Invert
      <br />
      annotations
    </>
  ),
  [tools.RESET_MODEL]: (
    <>
      Reset
      <br />
      model
    </>
  ),
  [tools.TRAIN_MODEL]: (
    <>
      Train
      <br />
      model
    </>
  ),
  [tools.RUN_MODEL]: (
    <>
      Run
      <br />
      model
    </>
  ),
};
