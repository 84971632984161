import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ArrowLinkIcon, DropDownIcon, DropUpIcon } from 'assets/icons';
import Logo from 'components/Logo';
import {
  resetMaskCanvasCache,
  resetScribbleCanvasCache,
} from 'components/DemoPage/ImagePlayer/canvas';
import config from 'config';

import './Navigation.scss';

// import styles from 'assets/styles/constants.scss'
// TODO: Somehow the constants cannot be imported...
// Similar problem, no answer: https://stackoverflow.com/questions/57315250

const getLogoIconWidth = () => {
  const screenWidth = window.innerWidth;
  // TODO: Reuse the values from the SCSS constants file.
  if (screenWidth > 768) {
    return 167;
  } else {
    return 125;
  }
};

const Navigation = (props) => {
  const { currentDatasetSemId, className, isFadeInOnOpen } = props;
  const [logoIconWidth, setLogoIconWidth] = React.useState(getLogoIconWidth());

  React.useState(() => {
    const updateLogoIconWidth = () => {
      const newLogoIconWidth = getLogoIconWidth();
      if (newLogoIconWidth !== logoIconWidth) {
        setLogoIconWidth(newLogoIconWidth);
      }
    };
    window.addEventListener('resize', updateLogoIconWidth);
    return () => window.removeEventListener('resize', updateLogoIconWidth);
  });

  return (
    <nav
      className={classNames(className, { 'fade-in-on-open': isFadeInOnOpen })}
    >
      <div className={'left'}>
        <Logo width={logoIconWidth} isDemo={true} />
        {currentDatasetSemId && (
          <DropDown currentDatasetSemId={currentDatasetSemId} />
        )}
      </div>
      <ContactButton />
    </nav>
  );
};

Navigation.propTypes = {
  currentDatasetSemId: PropTypes.string,
  className: PropTypes.string,
  isFadeInOnOpen: PropTypes.bool,
};

Navigation.defaultProps = {
  currentDatasetSemId: null,
  className: null,
  isFadeInOnOpen: false,
};

const ContactButton = () => {
  return (
    <div
      className={'contact-button'}
      onMouseDown={(event) => event.preventDefault()}
      onClick={() => window.location.assign(config.contactEmailStr)}
    >
      Contact
      <div className={'icon-wrapper'}>
        <ArrowLinkIcon />
      </div>
    </div>
  );
};

const DropDown = (props) => {
  const { currentDatasetSemId } = props;

  const [isOpened, setIsOpened] = React.useState(false);

  const history = useHistory();

  const demoMetadataList = useSelector((state) => state.demoMetadataList);
  const [currentDatasetMetadata] = demoMetadataList.filter(
    (md) => md.datasetSemId === currentDatasetSemId,
  );

  return (
    <div className={'dropdown'} onMouseDown={(event) => event.preventDefault()}>
      <div className={'selected-option'} onClick={() => setIsOpened(!isOpened)}>
        <div>{currentDatasetMetadata.datasetName}</div>
        <div className={'icon-wrapper'}>
          {isOpened ? <DropDownIcon /> : <DropUpIcon />}
        </div>
      </div>
      {
        <div className={classNames('content', { opened: isOpened })}>
          <div className={'tip'} />
          <div className={'options-wrapper'}>
            {demoMetadataList.map((md, index) => (
              <div
                key={index}
                className={classNames('option', {
                  active: md.datasetSemId === currentDatasetSemId,
                })}
                onClick={() => {
                  resetMaskCanvasCache();
                  resetScribbleCanvasCache();
                  history.push(`/${md.datasetSemId}/1`);
                }}
              >
                {md.datasetName}
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default Navigation;
