import React, { useEffect } from 'react';

import {
  computeDisplayedImgSize,
  getImgSize,
  imageDataToImage,
} from 'utils/misc';

import './MaskCanvas.scss';

let MASK_CANVAS_CACHE = {};

export const resetMaskCanvasCache = () => {
  MASK_CANVAS_CACHE = {};
};

const MaskCanvas = (props) => {
  const { imgId, imgRef, data, canvasRef, onClick } = props;

  useEffect(() => {
    // Resize canvas and redraw image.

    const img = imgRef.current;
    const canvas = canvasRef.current;

    const updateCanvas = () => {
      const { width, height } = computeDisplayedImgSize(img);
      canvas.width = width;
      canvas.height = height;

      let dataToShow = null;
      if (data === null) {
        const imageDataCache = MASK_CANVAS_CACHE[imgId] || null;
        if (imageDataCache !== null) {
          dataToShow = imageDataCache;
        }
      } else {
        dataToShow = data;
        MASK_CANVAS_CACHE[imgId] = data;
      }

      if (dataToShow !== null) {
        const { mask, maskWidth, maskHeight } = dataToShow;
        const imageData = new ImageData(
          Uint8ClampedArray.from(mask),
          maskWidth,
          maskHeight,
        );
        const image = imageDataToImage(imageData);

        const context = canvas.getContext('2d');
        image.onload = () =>
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
      }
    };

    if (canvas) {
      updateCanvas();
    }

    window.addEventListener('resize', updateCanvas);

    return () => {
      window.removeEventListener('resize', updateCanvas);
    };
  }, [imgId, imgRef, canvasRef, data]);

  return (
    <canvas
      className={'mask-canvas'}
      ref={canvasRef}
      onClick={(event) => {
        event.preventDefault();

        // Map event to canvas coordinates:
        const rect = canvasRef.current.getBoundingClientRect();
        let x = event.clientX - rect.x;
        let y = event.clientY - rect.y;

        // Map canvas to image coordinates:
        const dispImgSize = computeDisplayedImgSize(imgRef.current);
        const imgSize = getImgSize(imgRef.current);
        const xImg = Math.min(
          Math.max(0, (x * imgSize.width) / dispImgSize.width),
          imgSize.width - 1,
        );
        const yImg = Math.min(
          Math.max(0, (y * imgSize.height) / dispImgSize.height),
          imgSize.height - 1,
        );

        // Image size:
        onClick && onClick({ x: xImg, y: yImg });
      }}
    />
  );
};

export default MaskCanvas;
