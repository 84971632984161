import React from 'react';

import './WorkflowSection.scss';

const STATIC_ROOT = '/static/image';

const getImagePath = () => {
  const width = window.innerWidth;
  let path;
  switch (true) {
    case width > 1320:
      path = `${STATIC_ROOT}/workflow-d.svg`;
      break;
    case width > 570:
      path = `${STATIC_ROOT}/workflow-t.svg`;
      break;
    default:
      path = `${STATIC_ROOT}/workflow-m.svg`;
  }
  return path;
};

const WorkflowSection = () => {
  const [imagePath, setImagePath] = React.useState(getImagePath());

  React.useEffect(() => {
    const updateImagePath = () => {
      const newImagePath = getImagePath();
      if (newImagePath !== imagePath) {
        setImagePath(newImagePath);
      }
    };
    window.addEventListener('resize', updateImagePath);
    return () => window.removeEventListener('resize', updateImagePath);
  });

  return (
    <div className={'section workflow'}>
      <div className={'section-content'}>
        <div className={'section-title'}>
          <h2>
            The model takes scribbles as input and learns from scratch in real
            time
          </h2>
        </div>
        <div className={'image-wrapper'}>
          <img src={imagePath} alt={''} />
        </div>
      </div>
    </div>
  );
};

export default WorkflowSection;
