import axios from 'axios';
import urlJoin from 'url-join';

import { colors } from 'appConstants';

import config from '../config';

const imageDataToJSON = (imageData) => ({
  width: imageData.width,
  height: imageData.height,
  data: Array.from(imageData.data),
});

const colorsToJSON = () => ({
  fg: colors.FG_COLOR,
  bg: colors.BG_COLOR,
});

class BackendAPI {
  constructor() {
    this.backendURL = config.backend.api;
  }

  getDemoMetadataList = ({ callbackOnFulfilled }) => {
    axios.get(urlJoin(this.backendURL, '/api/dataset')).then((response) => {
      callbackOnFulfilled(
        response.data.map((d) => ({
          datasetName: d.name,
          datasetSemId: d.sem_id,
          datasetRootURL: urlJoin(this.backendURL, d.root_url),
          numSlices: d.num_slices,
        })),
      );
    });
  };

  trainModel = ({
    datasetSemId,
    sliceIndex,
    modelId,
    maskImageData,
    callbackOnFulfilled,
    callbackOnRejected,
  }) => {
    axios
      .post(urlJoin(this.backendURL, '/api/model/train'), {
        dataset_sem_id: datasetSemId,
        slice_index: sliceIndex,
        model: {
          model_id: modelId,
        },
        mask: imageDataToJSON(maskImageData),
        colors: colorsToJSON(),
      })
      .then((response) => {
        const rawData = response.data;
        return callbackOnFulfilled({
          mask: rawData.data,
          maskWidth: rawData.width,
          maskHeight: rawData.height,
        });
      })
      .catch((error) => callbackOnRejected(parseErrorMessage(error)));
  };

  runModel = ({
    datasetSemId,
    sliceIndex,
    modelId,
    callbackOnFulfilled,
    callbackOnRejected,
  }) => {
    axios
      .post(urlJoin(this.backendURL, '/api/model/run'), {
        dataset_sem_id: datasetSemId,
        slice_index: sliceIndex,
        model: {
          model_id: modelId,
        },
        colors: colorsToJSON(),
      })
      .then((response) => {
        const rawData = response.data;
        return callbackOnFulfilled({
          mask: rawData.data,
          maskWidth: rawData.width,
          maskHeight: rawData.height,
        });
      })
      .catch((error) => callbackOnRejected(parseErrorMessage(error)));
  };

  invertAnnotations = ({
    maskImageData,
    coordinate,
    callbackOnFulfilled,
    callbackOnRejected,
  }) => {
    // TODO: mask image data to json, we do it multiple times, abstract?
    axios
      .post(urlJoin(this.backendURL, '/api/tool/invert_annotations'), {
        mask: imageDataToJSON(maskImageData),
        colors: colorsToJSON(),
        coordinate,
      })
      .then((response) => {
        const rawData = response.data;
        callbackOnFulfilled({
          mask: rawData.data,
          maskWidth: rawData.width,
          maskHeight: rawData.height,
        });
      })
      .catch((error) => callbackOnRejected(parseErrorMessage(error)));
  };
}

const parseErrorMessage = (error) => {
  let message;
  switch (error.response.status) {
    case 455:
      message = error.response.data.detail;
      break;
    default:
      message = {
        title: `Error ${error.response.status}`,
        description: JSON.stringify(error.response.data.detail),
      };
  }
  return message;
};

export const backendAPI = new BackendAPI();
