import React, { useContext } from 'react';
import classNames from 'classnames';
import urlJoin from 'url-join';

import { colors } from 'appConstants';
import { backendAPI } from 'utils/api';
import {
  canvasIsEmpty,
  getImgId,
  getImgSize,
  readImageDataFromCanvas,
} from 'utils/misc';

import DemoPageContext from 'components/DemoPage/context';
import { tools } from 'components/DemoPage/demoConstants';

import { MaskCanvas, ScribbleCanvas } from './canvas';
import './ImagePlayer.scss';

const ImagePlayer = () => {
  const {
    datasetSemId,
    datasetRootURL,
    sliceIndex,
    currentToolId,
    segResults,
    imageIsShown,
    scribbleIsShown,
    maskIsShown,
    errorMessages,
    setErrorMessages,
    setIsRunning,
    setSegResults,
    imgRef,
    maskCanvasRef,
    scribbleCanvasRef,
  } = useContext(DemoPageContext);

  const imgId = getImgId({ datasetSemId, sliceIndex });

  let colorToDraw;
  switch (currentToolId) {
    case tools.ANNOTATE_FG:
      colorToDraw = colors.FG_COLOR;
      break;
    case tools.ANNOTATE_BG:
      colorToDraw = colors.BG_COLOR;
      break;
    default:
      colorToDraw = null;
  }

  return (
    <div className={'image-player'}>
      <div className={'image-wrapper'}>
        <img
          src={urlJoin(datasetRootURL, `${sliceIndex}.jpg`)}
          alt={`${sliceIndex}.jpg`}
          ref={imgRef}
          style={{ visibility: imageIsShown ? 'visible' : 'hidden' }}
        />
      </div>
      <div
        className={classNames('canvas-wrapper', {
          active: [tools.INVERT_ANNOTATIONS].includes(currentToolId),
        })}
        style={{ visibility: maskIsShown ? 'visible' : 'hidden' }}
      >
        <MaskCanvas
          imgId={imgId}
          imgRef={imgRef}
          canvasRef={maskCanvasRef}
          data={segResults}
          onClick={({ x, y }) => {
            if (currentToolId !== tools.INVERT_ANNOTATIONS) {
              return;
            }
            const canvas = maskCanvasRef.current;
            if (canvasIsEmpty(canvas)) {
              setErrorMessages([
                ...errorMessages,
                {
                  title: 'Cannot invert annotations',
                  description:
                    'Annotations can be inverted only on segmentation masks. However, no mask is found. As the first step, train or run model to produce a segmentation mask.',
                },
              ]);
            } else {
              setIsRunning(true);
              backendAPI.invertAnnotations({
                maskImageData: readImageDataFromCanvas(
                  canvas,
                  getImgSize(imgRef.current),
                ),
                coordinate: { x: x, y: y },
                callbackOnFulfilled: ({ mask, maskWidth, maskHeight }) => {
                  setIsRunning(false);
                  setSegResults({ mask, maskWidth, maskHeight });
                },
                callbackOnRejected: (errorMessage) => {
                  setIsRunning(false);
                  const { title, description } = errorMessage;
                  setErrorMessages([...errorMessages, { title, description }]);
                },
              });
            }
          }}
        />
      </div>
      <div
        className={classNames('canvas-wrapper', {
          active: [tools.ANNOTATE_FG, tools.ANNOTATE_BG].includes(
            currentToolId,
          ),
        })}
        style={{ visibility: scribbleIsShown ? 'visible' : 'hidden' }}
      >
        <ScribbleCanvas
          imgId={imgId}
          imgRef={imgRef}
          canvasRef={scribbleCanvasRef}
          drawIsAllowed={[tools.ANNOTATE_FG, tools.ANNOTATE_BG].includes(
            currentToolId,
          )}
          color={colorToDraw}
        />
      </div>
    </div>
  );
};

export default ImagePlayer;
