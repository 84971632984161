import React from 'react';

import Logo from 'components/Logo';
import config from 'config';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className={'unselectable'}>
      <a href={config.homeUrl} target={'_blank'} rel={'noreferrer'}>
        <Logo />
      </a>
      <div className={'copyright'}>©2021 · Zürich</div>
    </footer>
  );
};

export default Footer;
