import React, { useState } from 'react';
import urlJoin from 'url-join';
import axios from 'axios';
import classNames from 'classnames';
import validate from 'validate.js';

import config from 'config';
import Spinner from 'components/Spinner';

import './ContactSection.scss';

const ContactSection = () => {
  return (
    <div className={'contact-area'}>
      <div className={'image-wrapper'}>
        <img src={'/static/image/contact-form.jpg'} alt={''} />
      </div>

      <div className={'contact-form-wrapper'}>
        <div className={'section contact-form'}>
          <div className={'section-content'}>
            <h1>Do you want to see how the model performs on your data?</h1>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

const Form = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSent, setIsSent] = useState(false);

  let spinner = null;
  if (isWaiting) {
    spinner = (
      <div className={'spinner-wrapper'}>
        <Spinner />
      </div>
    );
  }

  let successMessage = null;
  if (isSent) {
    successMessage = (
      <div className={'success-message'}>
        Done, thank you! We have received your message and we will come back to
        you shortly.
      </div>
    );
  }

  return (
    <form
      className={'contact'}
      onSubmit={(event) => {
        event.preventDefault();
        setIsWaiting(true);
        axios
          .post(urlJoin(config.backend.bot, '/contact_form_message'), {
            source: '#iSegBio',
            email,
            message,
          })
          .then(() => {
            setIsWaiting(false);
            setIsSent(true);
            setIsDisabled(true);
          })
          .catch((error) => {
            setIsWaiting(false);
            console.error(error.response.data);
          });
      }}
    >
      <div className={'content-wrapper'}>
        <div
          className={classNames('input-forms', { hidden: isWaiting | isSent })}
        >
          <EmailInput
            email={email}
            setEmail={setEmail}
            setIsDisabled={setIsDisabled}
          />
          <MessageInput message={message} setMessage={setMessage} />
        </div>
        {spinner}
        <div
          className={classNames('success-message-wrapper', {
            'is-sent': isSent,
          })}
        >
          {successMessage}
        </div>
      </div>
      <input type="submit" value="Get in touch" disabled={isDisabled} />
    </form>
  );
};

const EmailInput = ({ email, setEmail, setIsDisabled }) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  return (
    <div className={'input-wrapper email'}>
      <label
        htmlFor="email"
        className={classNames({ filled: email.length > 0 })}
      >
        Contact email
      </label>
      <input
        id={'email'}
        type={'email'}
        name={'email'}
        value={email}
        placeholder={'Enter your email'}
        className={classNames({ invalid: emailErrorMessage !== null })}
        onChange={(event) => {
          emailErrorMessage && setEmailErrorMessage(null);
          setEmail(event.target.value);
        }}
        onBlur={(event) => {
          const result = validate.single(event.target.value, {
            email: { message: 'Please enter a correct email address.' },
          });
          if (result && email !== '') {
            const [error] = result;
            setIsDisabled(true);
            setEmailErrorMessage(error);
          } else {
            if (email === '') {
              setIsDisabled(true);
            } else {
              setIsDisabled(false);
            }
            setEmailErrorMessage(null);
          }
        }}
      />
      <div
        className={classNames('error-message', {
          filled: emailErrorMessage !== null,
        })}
      >
        {emailErrorMessage}
      </div>
    </div>
  );
};

const MessageInput = ({ message, setMessage }) => {
  return (
    <div className={'input-wrapper message'}>
      <label
        htmlFor="message"
        className={classNames({ filled: message.length > 0 })}
      >
        Message
      </label>
      <textarea
        id={'message'}
        name={'message'}
        value={message}
        placeholder={'Enter your message'}
        onChange={(event) => setMessage(event.target.value)}
        rows="1"
      />
    </div>
  );
};

export default ContactSection;
