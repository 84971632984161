import React from 'react';

import { LogoIcon } from 'assets/logo';
import { useHistory } from 'react-router';

import './Logo.scss';

const DemoLabel = () => {
  return <div className={'demo-label'}>Demo</div>;
};

const Logo = ({ width, isDemo = false }) => {
  const history = useHistory();

  let demoLabel = null;
  if (isDemo) {
    demoLabel = <DemoLabel />;
  }

  return (
    <div className={'logo-wrapper'} onClick={() => history.push('/')}>
      <LogoIcon width={width} className={'logo'} />
      {demoLabel}
    </div>
  );
};

export default Logo;
