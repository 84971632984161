import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import config from '../config';

import * as allReducers from './reducers';

const stateSaver = (store) => (next) => (action) => {
  const result = next(action);
  localStorage[config.app.localStorageKey] = JSON.stringify(store.getState());
  return result;
};

const MIDDLEWARE = [thunk, stateSaver];
const REDUCERS = combineReducers(allReducers);

const storeFactory = (initialState) => {
  if (localStorage[config.app.localStorageKey]) {
    initialState = JSON.parse(localStorage[config.app.localStorageKey]);
  }
  return applyMiddleware(...MIDDLEWARE)(createStore)(REDUCERS, initialState);
};

export default storeFactory;
