import { tools } from './demoConstants';

export const KEY_MAP = {
  [tools.ANNOTATE_FG]: 'f',
  [tools.ANNOTATE_BG]: 'b',
  [tools.INVERT_ANNOTATIONS]: 'i',
  [tools.RESET_MODEL]: 'ctrl+d',
  [tools.TRAIN_MODEL]: 't',
  [tools.RUN_MODEL]: 'r',
  [tools.PREV_IMAGE]: '[',
  [tools.NEXT_IMAGE]: ']',
  [tools.UNDO]: 'z',
  [tools.REDO]: 'y',
  [tools.TOGGLE_IMAGE]: 'ctrl+i',
  [tools.TOGGLE_SCRIBBLES]: 'ctrl+s',
  [tools.TOGGLE_MASK]: 'ctrl+m',
};

export const mapKeyMapToLabel = (key) => {
  return key.split('+').join(' + ');
};
