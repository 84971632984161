import React from 'react';
import classNames from 'classnames';

import './Popup.scss';

const Popup = ({ message, onClose, popupClassName }) => {
  return (
    <div className={classNames('popup-wrapper', popupClassName)}>
      <div className={'popup'}>
        {onClose ? (
          <div className={'close-icon'} onClick={onClose}>
            x
          </div>
        ) : null}
        <div className={'message'}>{message}</div>
      </div>
    </div>
  );
};

export default Popup;
export const ProcessingPopup = () => {
  return <Popup popupClassName={'processing'} message={'Processing...'} />;
};
