import initState from './initState';

import * as actions from './actions';

export const app = (state = initState.app, action) => {
  return state;
};

export const demoMetadataList = (
  state = initState.demoMetadataList,
  action,
) => {
  switch (action.type) {
    case actions.UPDATE_DEMO_METADATA_LIST:
      return action.data;
    default:
      return state;
  }
};
