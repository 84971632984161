import config from '../config';

const initState = {
  app: {
    version: config.app.version,
  },
  demoMetadataList: null,
};

export default initState;
