import React from 'react';

import './NotFoundPage.scss';

const NotFoundPage = () => (
  <div className={'not-found'}>
    <h1>Page not found</h1>
  </div>
);

export default NotFoundPage;
