export const urls = Object.freeze({
  HOME: '/',
});

export const deviceTypes = Object.freeze({
  DESKTOP: 'd',
  TABLET: 't',
  MOBILE: 'm',
});

export const colors = Object.freeze({
  FG_COLOR: '#4285F4',
  BG_COLOR: '#9763F6',
});
