import dateFormat from 'dateformat';
import _ from 'lodash';

export const getImgSize = (img) => ({
  width: img.naturalWidth,
  height: img.naturalHeight,
});

export const getImgId = ({ datasetSemId, sliceIndex }) => {
  return `${datasetSemId}-${sliceIndex}`;
};

export const computeDisplayedImgSize = (img) => {
  // Real image size:
  const { width: imgWidth, height: imgHeight } = getImgSize(img);

  // Displayed image size with offsets:
  const displayedFullImgWidth = img.width;
  const displayedFullImgHeight = img.height;

  // NOTE: Data player is not properly scaled to the parent div, that is why we correct for margins.
  let xMargin = 0;
  let yMargin = 0;
  const imgAspect = imgWidth / imgHeight;
  const displayImgAspect = displayedFullImgWidth / displayedFullImgHeight;
  if (displayImgAspect < imgAspect) {
    yMargin =
      displayedFullImgHeight - (displayedFullImgWidth * imgHeight) / imgWidth;
  } else if (displayImgAspect > imgAspect) {
    xMargin =
      displayedFullImgWidth - (displayedFullImgHeight * imgWidth) / imgHeight;
  }

  return {
    width: img.width - xMargin,
    height: img.height - yMargin,
  };
};

export const draw = ({ canvas, clickCoords, radius = 5, color = 'red' }) => {
  const { x, y } = clickCoords;
  const context = canvas.getContext('2d');
  context.imageSmoothingEnabled = false;
  context.webkitImageSmoothingEnabled = false;
  context.mozImageSmoothingEnabled = false;

  context.beginPath();
  context.fillStyle = color;
  context.arc(x, y, radius, 0, 2 * Math.PI, false);

  context.fill();
  context.closePath();
};

export const imageDataToImage = (imageData) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  context.putImageData(imageData, 0, 0);

  const image = new Image();
  image.src = canvas.toDataURL();
  return image;
};

export const clearCanvas = (canvas) =>
  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

export const writeImageDataToCanvas = (canvas, imageData, callback = null) => {
  const context = canvas.getContext('2d');

  // Solution using a temporary canvas should also work:
  // https://stackoverflow.com/questions/34866171/resize-html-canvas-with-scale
  const image = imageDataToImage(imageData);
  image.onload = () => {
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    callback && callback();
  };
};

export const readImageDataFromCanvas = (canvas, { width, height }) => {
  // TODO: Why can't we get width and height from canvas? I think it should be possible.
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = width;
  tmpCanvas.height = height;
  const context = tmpCanvas.getContext('2d');
  context.drawImage(canvas, 0, 0, width, height);
  return context.getImageData(0, 0, width, height);
};

export const canvasIsEmpty = (canvas) => {
  const imageData = canvas
    .getContext('2d')
    .getImageData(0, 0, canvas.width, canvas.height);
  return _.isEqual(new Set(imageData.data), new Set([0]));
};

export const sampleModelId = () => {
  return dateFormat(new Date(), 'yyyy_mm_dd-HH_MM_ss_l', true);
};
