import React from 'react';
import PropTypes from 'prop-types';

import { CloseIcon, WarningIcon } from 'assets/icons';

import './MessagePanel.scss';

const MessagePanel = (props) => {
  const { errorMessages, setErrorMessages } = props;
  return (
    <div className={'message-panel'}>
      {errorMessages.map((message, index) => (
        <Message
          key={index}
          {...message}
          onClose={() => {
            setErrorMessages([
              ...errorMessages.slice(0, index),
              ...errorMessages.slice(index + 1, errorMessages.length),
            ]);
          }}
        />
      ))}
    </div>
  );
};

MessagePanel.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.object),
  setErrorMessages: PropTypes.func,
};

MessagePanel.defaultProps = {
  errorMessages: [],
  setErrorMessages: (messages) => messages,
};

const Message = (props) => {
  const { title, description, onClose } = props;
  return (
    <div className={'message'}>
      <div className={'message-icon-wrapper'}>
        <WarningIcon />
      </div>
      <div className={'content-wrapper'}>
        <div className={'title'}>{title}</div>
        <div className={'description'}>{description}</div>
      </div>
      <div
        className={'close-icon-wrapper'}
        onClick={() => onClose && onClose()}
      >
        <CloseIcon />
      </div>
    </div>
  );
};

export default MessagePanel;
