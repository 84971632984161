import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { CheckMarkIcon } from 'assets/icons';

import { KEY_MAP, mapKeyMapToLabel } from 'components/DemoPage/demoHotKeys';

import './Checkboxes.scss';

const Checkboxes = (props) => {
  const { items } = props;
  return (
    <form className={'checkboxes'}>
      {items.map(({ isChecked, onChange, ...otherProps }, item) => (
        <Checkbox
          key={item}
          isChecked={isChecked}
          onChange={() => {
            onChange((isChecked) => !isChecked);
          }}
          {...otherProps}
        />
      ))}
    </form>
  );
};

const Checkbox = (props) => {
  const { isChecked, label, hotKeyId, onChange } = props;
  return (
    <GlobalHotKeys handlers={{ [hotKeyId]: onChange }}>
      <label
        className={'checkbox'}
        onMouseDown={(event) => event.preventDefault()}
      >
        <input
          type="checkbox"
          value={label}
          checked={isChecked}
          onChange={onChange}
        />
        <div className="checkmark">{isChecked && <CheckMarkIcon />}</div>
        <div className={'shortcut-key'}>
          {mapKeyMapToLabel(KEY_MAP[hotKeyId])}
        </div>
        {label}
      </label>
    </GlobalHotKeys>
  );
};

export default Checkboxes;
